<template>
    <div class="container -mt-8 sm:mt-0" ref="elements">
        <div class="container bg-gray-200 rounded-lg">
            <div class="container p-4">
                <div class="grid grid-cols-1 gap-4 mb-4 lg:grid-cols-2">
                    <div class="flex flex-row sm:flex-col">
                        <label class="w-1/3 sm:w-full">Kaufpreis (brutto)</label>
                        <input
                            type="number"
                            name="purchaseprice"
                            placeholder="z.B.: 2000"
                            v-model="formdata.products.price"
                            class="w-1/2 py-1 font-bold text-center sm:w-full"
                            @input="sendData"
                            required
                        />
                        <span
                            v-if="!isBudget(formdata.products.price)"
                            class="hidden text-red-600 sm:block"
                            >{{ budgetMessage(formdata.products.price) }}</span
                        >
                    </div>
                    <span
                        v-if="!isBudget(formdata.products.price)"
                        class="flex text-red-600 sm:hidden"
                        >{{ budgetMessage(formdata.products.price) }}</span
                    >
                    <div class="flex flex-row sm:flex-col">
                        <label class="w-1/3 sm:w-full">Bruttogehalt</label>
                        <input
                            type="number"
                            name="grosssalary"
                            placeholder="3000"
                            v-model="formdata.salary.grossmonthlysalary"
                            class="w-1/2 py-1 font-bold text-center sm:w-full"
                            @input="sendData"
                            required
                        />
                        <span class="text-red-600">{{ errorMessageGross }}</span>
                    </div>
                    <div class="col-span-1 space-y-2 sm:space-y-0">
                        <label class="pb-2 sm:pb-0">Steuerklasse</label>
                        <div
                            :class="`radio-toolbar-yellow`"
                            class="flex flex-row -ml-2 space-x-2 radio-toolbar sm:space-x-4 sm:-ml-4"
                        >
                            <template v-for="taxClass in taxClasses">
                                <input
                                    type="radio"
                                    :id="`class${taxClass}`"
                                    class="rounded-full"
                                    v-model="formdata.salary.taxclass"
                                    @change="sendData"
                                    name="mpp-tax-class"
                                    :value="taxClass"
                                />
                                <label class="cursor-pointer" :for="`class${taxClass}`">{{
                                    taxClass
                                }}</label>
                            </template>
                        </div>
                    </div>

                    <div :class="[toggleGrantType]" class="col-span-1 space-y-2 sm:space-y-0">
                        <label class="pb-2 sm:pb-0">Art des Zuschusses</label>
                        <div
                            :class="`radio-toolbar-yellow`"
                            class="flex flex-row -ml-6 space-x-6 radio-toolbar"
                        >
                            <input
                                type="radio"
                                id="EUR"
                                v-model="formdata.salary.grant_type"
                                @change="sendData"
                                name="mpp-grant_type"
                                value="EUR"
                            />
                            <label for="EUR">Fester Betrag</label>
                            <input
                                type="radio"
                                v-model="formdata.salary.grant_type"
                                @change="sendData"
                                id="percent"
                                name="mpp-grant_type"
                                value="percent"
                            />
                            <label for="percent">Prozentualer Zuschuss</label>
                        </div>
                    </div>
                    <div class="col-span-1 space-y-2 sm:space-y-0">
                        <label>Übernahme des Rundum-Schutzes</label>
                        <div
                            :class="`radio-toolbar-yellow`"
                            class="flex flex-row -ml-6 space-x-6 radio-toolbar"
                        >
                            <input
                                type="radio"
                                id="arbeitnehmer"
                                @change="sendData"
                                v-model="formdata.salary.calcAGRS"
                                name="mpp-calcAGRS"
                                value="0"
                                checked
                            />
                            <label for="arbeitnehmer">Arbeitnehmer</label>
                            <input
                                type="radio"
                                v-model="formdata.salary.calcAGRS"
                                id="arbeitgeber"
                                @change="sendData"
                                name="mpp-calcAGRS"
                                value="1"
                            />
                            <label for="arbeitgeber">Arbeitgeber</label>
                        </div>
                    </div>
                    <div
                        :class="[grantType == 'percent' ? '' : 'hidden']"
                        class="lg:col-start-2 radio-toolbar-number"
                    >
                        <p>Arbeitgeberzuschuss zur Leasingrate in %</p>
                        <input
                            type="number"
                            min="0"
                            max="100"
                            name="arbeitgeberzuschuss"
                            v-model="formdata.salary.grant_percentage"
                            @change="sendData"
                            @input="
                                () => {
                                    if (formdata.salary.grant_percentage > 100) {
                                        formdata.salary.grant_percentage = 100
                                    }
                                    if (formdata.salary.grant_percentage > 0) {
                                        formdata.salary.grant_fixvalue = 0
                                    }
                                }
                            "
                            placeholder="10"
                            class="w-full py-1 font-bold text-center"
                            required
                            :disabled="grantConfigObjects.leasing.disabled"
                        />
                        <span class="text-red-600">{{ errorMessagePercent }}</span>
                    </div>
                    <div
                        :class="[grantType == 'EUR' ? '' : 'hidden']"
                        class="lg:col-start-2 radio-toolbar-number"
                    >
                        <p>Arbeitgeberzuschuss zur Leasingrate in €</p>
                        <input
                            type="number"
                            min="0"
                            max="1000"
                            name="arbeitgeberzuschuss"
                            v-model="formdata.salary.grant_fixvalue"
                            @change="sendData"
                            @input="
                                () => {
                                    if (formdata.salary.grant_fixvalue > 0) {
                                        formdata.salary.grant_percentage = 0
                                    }
                                }
                            "
                            placeholder="30"
                            class="w-full py-1 font-bold text-center"
                            required
                            :disabled="
                                grantConfigObjects.fixed && grantConfigObjects.fixed.disabled
                            "
                        />
                        <span class="text-red-600">{{ errorMessageFixed }}</span>
                    </div>
                    <div class="relative flex justify-center w-full my-8 sm:hidden">
                        <div class="w-full border-b-2 border-white"></div>
                        <button
                            @click="showFurtherFields()"
                            class="absolute flex content-center px-2 py-1 text-xl text-white transform -translate-y-1/2 bg-white rounded-full sm:px-20"
                        >
                            <span v-if="showFields" class="pt-1 pr-2 text-base text-black"
                                >Erweiterte Angaben verbergen</span
                            >
                            <span v-if="!showFields" class="pt-1 pr-2 text-base text-black"
                                >Erweiterte Angaben anzeigen</span
                            >
                            <svg
                                v-if="!showFields"
                                xmlns="http://www.w3.org/2000/svg"
                                class="w-8 h-6 text-white"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                            <svg
                                v-if="showFields"
                                xmlns="http://www.w3.org/2000/svg"
                                class="w-8 h-6 text-white"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 15l7-7 7 7"
                                />
                            </svg>
                        </button>
                    </div>
                    <div
                        :class="{ hidden: !showFields }"
                        class="grid-cols-1 gap-4 mb-4 space-y-3 col-span-full sm:grid lg:grid-cols-2 sm:space-y-0"
                    >
                        <div class="col-span-full">
                            <label>Bundesland</label>
                            <select
                                type="text"
                                name="state"
                                v-model="formdata.salary.state"
                                @change="sendData"
                                class="w-full py-2 text-center"
                            >
                                <option value="BW">Baden-Württemberg</option>
                                <option value="BY">Bayern</option>
                                <option value="BE">Berlin</option>
                                <option value="BB">Brandenburg</option>
                                <option value="HB">Bremen</option>
                                <option value="HH">Hamburg</option>
                                <option value="HE">Hessen</option>
                                <option value="MV">Mecklenburg-Vorpommern</option>
                                <option value="NI">Niedersachsen</option>
                                <option value="NW">Nordrhein-Westfalen</option>
                                <option value="RP">Rheinland-Pfalz</option>
                                <option value="SL">Saarland</option>
                                <option value="SN">Sachsen</option>
                                <option value="ST">Sachsen-Anhalt</option>
                                <option value="SH">Schleswig-Holstein</option>
                                <option value="TH">Thüringen</option>
                            </select>
                        </div>
                        <div>
                            <label>Zusatzbeitrag Krankenkasse in % (von {{ healthMinValue }} bis {{ healthMaxValue }})</label>
                            <input
                                type="number"
                                :min="healthMinValue"
                                :max="healthMaxValue"
                                step="0.01"
                                v-model="formdata.salary.healthinsurancecontribution"
                                @change="sendData"
                                class="w-full py-2 font-bold text-center"
                            />
                        </div>
                        <div>
                            <label>Kinderfreibetrag</label>
                            <select
                                type="text"
                                name="children"
                                v-model="formdata.salary.childtax"
                                @change="sendData"
                                class="w-full py-2 font-bold text-center"
                            >
                                <option value="0">0</option>
                                <option value="0.5">0,5</option>
                                <option value="1">1</option>
                                <option value="1.5">1,5</option>
                                <option value="2">2</option>
                                <option value="2.5">2,5</option>
                                <option value="3">3</option>
                                <option value="3.5">3,5</option>
                                <option value="4">4</option>
                                <option value="4.5">4,5</option>
                                <option value="5">5</option>
                                <option value="5.5">5,5</option>
                                <option value="6">6</option>
                            </select>
                        </div>
                        <div class="flex flex-row col-start-1">
                            <div class="w-1/6 checkbox-toolbar-yellow checkbox-toolbar">
                                <input
                                    type="checkbox"
                                    id="churchtax"
                                    class="rounded-full"
                                    v-model="formdata.salary.churchtax"
                                    @change="sendData"
                                    name="ischurchtax"
                                    :true-value="1"
                                    :false-value="0"
                                />
                                <label v-if="formdata.salary.churchtax == '0'" for="churchtax"
                                    >X</label
                                >
                                <label v-if="formdata.salary.churchtax == '1'" for="churchtax"
                                    >✓</label
                                >
                            </div>
                            <span class="self-center">Kirchensteuerpflicht</span>
                        </div>
                        <div class="flex flex-row">
                            <div class="w-1/6 checkbox-toolbar-yellow checkbox-toolbar">
                                <input
                                    type="checkbox"
                                    id="healthinsurance"
                                    class="rounded-full"
                                    v-model="formdata.salary.healthinsurance"
                                    @change="sendData"
                                    name="ishealthinsurance"
                                    :true-value="1"
                                    :false-value="0"
                                />
                                <label
                                    v-if="formdata.salary.healthinsurance == '0'"
                                    for="healthinsurance"
                                    >X</label
                                >
                                <label
                                    v-if="formdata.salary.healthinsurance == '1'"
                                    for="healthinsurance"
                                    >✓</label
                                >
                            </div>
                            <span class="self-center">Krankenversicherungspflicht</span>
                        </div>
                        <div class="flex flex-row">
                            <div class="w-1/6 checkbox-toolbar-yellow checkbox-toolbar">
                                <input
                                    type="checkbox"
                                    id="pensiontax"
                                    class="rounded-full"
                                    v-model="formdata.salary.pensioninsurance"
                                    @change="sendData"
                                    name="ispensiontax"
                                    :true-value="1"
                                    :false-value="0"
                                />
                                <label
                                    v-if="formdata.salary.pensioninsurance == '0'"
                                    for="pensiontax"
                                    >X</label
                                >
                                <label
                                    v-if="formdata.salary.pensioninsurance == '1'"
                                    for="pensiontax"
                                    >✓</label
                                >
                            </div>
                            <span class="self-center">Rentenversicherungspflicht</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showError" class="text-center">
            <span class="text-red-600"
                >Die Berechnung kann nicht ausgeführt werden. Bitte überprüfe deine Eingaben</span
            >
        </div>
        <div class="grid grid-cols-1 py-4 text-center lg:grid-cols-2 lg:text-left">
            <div class="flex flex-col">
                <p>So viel wird gespart</p>
                <div>
                    <h2 class="text-5xl font-bold">
                        {{ getLocalePrice(productData.mpp_saving_with_rest_total) }}
                    </h2>
                    <div class="flex justify-center text-base lg:text-xl lg:justify-start">
                        <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M30.667 14.0444C30.667 16.2045 28.9159 17.9555 26.7559 17.9555"
                                stroke="#FFD400"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M8.17794 9.22071C6.07427 10.5841 4.50791 12.6342 3.74535 15.0222H1.3335V22.8444H4.57972C5.25483 24.0281 6.14329 25.0766 7.20016 25.9368V29.6889C7.20016 30.2289 7.63793 30.6666 8.17794 30.6666H10.1335C10.6735 30.6666 11.1113 30.2289 11.1113 29.6889V28.071C13.6489 28.9195 16.3928 28.9245 18.9335 28.0853V29.6889C18.9335 30.2289 19.3713 30.6666 19.9113 30.6666H21.8668C22.4068 30.6666 22.8446 30.2289 22.8446 29.6889V25.9564C25.2874 24.0151 26.7243 21.0756 26.7557 17.9555C26.709 14.3999 24.8656 11.1096 21.8577 9.21289"
                                stroke="#FFD400"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M11.1113 10.1337C13.576 8.91377 16.4688 8.91377 18.9336 10.1337"
                                stroke="#FFD400"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M15.0224 9.15572C17.1825 9.15572 18.9336 7.40465 18.9336 5.24461C18.9336 3.08456 17.1825 1.3335 15.0224 1.3335C12.8624 1.3335 11.1113 3.08456 11.1113 5.24461C11.1113 7.40465 12.8624 9.15572 15.0224 9.15572Z"
                                stroke="#FFD400"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <p class="pt-1 pl-3 text-2xl">
                            <strong>{{ productData.saving_percentage }}%</strong>
                            gespart!
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex flex-col pt-4 lg:pt-0">
                <p>Die individuelle Nutzungsrate beträgt:</p>
                <h3 class="text-5xl font-bold">
                    {{ getLocalePrice(productData.leasing_net) }}
                </h3>
            </div>
        </div>

        <div class="relative flex justify-center w-full my-12">
            <div class="w-full border-b-2 border-black"></div>
            <button
                @click="showCalculation()"
                :disabled="showError"
                class="absolute flex content-center px-5 py-1 text-xl text-white transform -translate-y-1/2 bg-black rounded-full sm:px-20"
            >
                <span v-if="showTable" class="pr-6">Berechnung anzeigen</span>
                <span v-if="!showTable" class="pr-6">Berechnung verbergen</span>
                <svg
                    v-if="showTable"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-8 h-6 text-white"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
                <svg
                    v-if="!showTable"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-8 h-6 text-white"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 15l7-7 7 7"
                    />
                </svg>
            </button>
        </div>

        <div v-if="!showTable" class="py-12" :class="{ loading: isloading }">
            <h2 class="py-6 text-2xl font-semibold">Errechnung der monatlichen Kosten:</h2>

            <table class="w-full py-4 text-lg table-fixed">
                <thead>
                    <tr class="text-left border-b-4 border-black">
                        <th class="hidden w-1/2 sm:table-cell"></th>
                        <th class="flex px-1"><p class="self-start">ohne MPP</p></th>
                        <th class="table-cell w-1/3 sm:hidden"></th>
                        <th class="px-1 text-right sm:text-left">
                            <p>mit MPP</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="relative border-b-2 border-black">
                        <td class="hidden w-1/2 sm:table-cell">
                            <p class="font-bold">Bruttomonatsgehalt</p>
                        </td>
                        <td class="pt-10 pb-4 sm:pt-4">
                            <p class="pt-2">
                                {{ getLocalePrice(formdata.salary.grossmonthlysalary) }}
                            </p>
                        </td>
                        <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                            <p class="pt-2 mx-auto font-bold">Bruttomonatsgehalt</p>
                        </td>
                        <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                            <p>{{ getLocalePrice(formdata.salary.grossmonthlysalary) }}</p>
                        </td>
                    </tr>

                    <template v-for="amount in amounts">
                        <tr :key="amount.index" class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">{{ amount.label }}</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>0,00 €</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">{{ amount.label }}</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>{{ amount.text }}</p>
                            </td>
                        </tr>
                    </template>

                    <!-- <tr class="relative border-b-2 border-black">
                        <td class="hidden w-1/2 sm:table-cell">
                            <p class="font-bold">Umwandlungsbetrag Leasingrate</p>
                        </td>
                        <td class="pt-10 pb-4 sm:pt-4">
                            <p>0,00€</p>
                        </td>
                        <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                            <p class="pt-2 mx-auto font-bold">Umwandlungsbetrag Leasingrate</p>
                        </td>
                        <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                            <p>- {{ getLocalePrice(productData.leasing_gross) }}</p>
                        </td>
                    </tr>
                    <tr class="relative border-b-2 border-black">
                        <td class="hidden w-1/2 sm:table-cell">
                            <p class="font-bold">Umwandlungsbetrag Rundum-Schutz</p>
                        </td>
                        <td class="pt-10 pb-4 sm:pt-4">
                            <p>0,00€</p>
                        </td>
                        <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                            <p class="pt-2 mx-auto font-bold">Umwandlungsbetrag Rundum-Schutz</p>
                        </td>
                        <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                            <p>- {{ getLocalePrice(productData.conversion_amount_insurance) }}</p>
                        </td>
                    </tr>
                    <tr
                        v-if="formdata.salary.grant_percentage > 0"
                        class="relative border-b-4 border-black"
                    >
                        <td class="hidden w-1/2 sm:table-cell">
                            <p class="font-bold">Arbeitgeber-Zuschuss</p>
                        </td>
                        <td class="pt-10 pb-4 sm:pt-4">
                            <p>0,00€</p>
                        </td>
                        <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                            <p class="pt-2 mx-auto font-bold">Arbeitgeber-Zuschuss</p>
                        </td>
                        <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                            <p>+ {{ getLocalePrice(productData.grant) }}</p>
                        </td>
                    </tr> -->

                    <tr class="relative border-b-2 border-black">
                        <td class="hidden w-1/2 sm:table-cell">
                            <p class="font-bold">Berechnungsgrundlage</p>
                        </td>
                        <td class="pt-10 pb-4 sm:pt-4">
                            <p>{{ getLocalePrice(formdata.salary.grossmonthlysalary) }}</p>
                        </td>
                        <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                            <p class="pt-2 mx-auto font-bold">Berechnungsgrundlage</p>
                        </td>
                        <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                            <p>
                                {{ getLocalePrice(productData.calculation_base) }}
                            </p>
                        </td>
                    </tr>
                    <tr class="relative border-b-2 border-black">
                        <td class="hidden w-1/2 sm:table-cell">
                            <p class="font-bold">Steuern gesamt</p>
                        </td>
                        <td class="pt-10 pb-4 sm:pt-4">
                            <p>- {{ getLocalePrice(productDetails.tax_sum.gross) }}</p>
                        </td>
                        <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                            <p class="pt-2 mx-auto font-bold">Steuern gesamt</p>
                        </td>
                        <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                            <p>- {{ getLocalePrice(productDetails.tax_sum.net) }}</p>
                        </td>
                    </tr>
                    <tr class="relative border-b-4 border-black">
                        <td class="hidden w-1/2 sm:table-cell">
                            <p class="font-bold">Sozialabgaben gesamt</p>
                        </td>
                        <td class="pt-10 pb-4 sm:pt-4">
                            <p>
                                -
                                {{
                                    getLocalePrice(productDetails.social_contributions_total.gross)
                                }}
                            </p>
                        </td>
                        <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                            <p class="pt-2 mx-auto font-bold">Sozialabgaben gesamt</p>
                        </td>
                        <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                            <p>
                                -
                                {{ getLocalePrice(productDetails.social_contributions_total.net) }}
                            </p>
                        </td>
                    </tr>

                    <template v-for="net_amount in net_amounts">
                        <tr :key="net_amount.index" class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">{{ net_amount.label }}</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>0,00 €</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">{{ net_amount.label }}</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>{{ net_amount.text }}</p>
                            </td>
                        </tr>
                    </template>

                    <tr class="relative">
                        <td class="hidden w-1/2 sm:table-cell">
                            <p class="font-bold">Nettomonatsgehalt</p>
                        </td>
                        <td class="pt-10 pb-4 sm:pt-4">
                            <p>
                                {{ getLocalePrice(productDetails.salary.gross) }}
                            </p>
                        </td>
                        <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                            <p class="pt-2 mx-auto font-bold">Nettomonatsgehalt</p>
                        </td>
                        <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                            <p>
                                {{ getLocalePrice(productDetails.salary.net) }}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="flex flex-col py-4 text-center text-white bg-black">
                <span>Differenz Nettomonatsgehalt = Individuelle Nutzungsrate:</span>
                <span class="text-2xl font-bold">
                    {{ getLocalePrice(productData.leasing_net) }}
                </span>
            </div>

            <h2 class="py-4 pt-16 text-2xl font-semibold">
                So errechnet sich die Ersparnis zum Privatkauf:
            </h2>

            <table class="w-full text-lg table-fixed">
                <thead>
                    <tr class="text-left border-b-4 border-black">
                        <th class="hidden w-1/2 sm:table-cell"></th>
                        <th>Privatkauf mit Schutz</th>
                        <th class="table-cell w-1/3 sm:hidden"></th>
                        <th class="px-1 text-xs text-right sm:text-left sm:text-lg">
                            <p class="font-normal">
                                <strong class="text-lg">mit MPP</strong><br />
                                inkl. Rundum-Schutz
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="relative border-b-2 border-black">
                        <td class="hidden w-1/2 sm:table-cell">
                            <p class="font-bold">Gerät</p>
                        </td>
                        <td class="pt-10 pb-4 sm:pt-4">
                            <p>
                                {{ getLocalePrice(formdata.products.price) }}
                            </p>
                        </td>
                        <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                            <p class="pt-2 mx-auto font-bold">Gerät</p>
                        </td>
                        <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                            <p>
                                (24 Monate x
                                {{ getLocalePrice(productData.leasing_net) }}) =
                                {{ getLocalePrice(productData.leasing_net_total) }}
                            </p>
                        </td>
                    </tr>
                    <tr class="relative border-b-2 border-black">
                        <td class="hidden w-1/2 sm:table-cell">
                            <p class="font-bold">zzgl. Rundum-Schutz (Gehaltsumwandlung)</p>
                        </td>
                        <td class="pt-10 pb-4 sm:pt-4">
                            <p>
                                {{ getLocalePrice(productData.general_insurance) }}
                            </p>
                        </td>
                        <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                            <p class="pt-2 mx-auto font-bold">
                                zzgl. Rundum-Schutz (Gehaltsumwandlung)
                            </p>
                        </td>
                        <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                            <p>inklusive</p>
                        </td>
                    </tr>
                    <tr class="relative border-b-2 border-black">
                        <td class="hidden w-1/2 sm:table-cell">
                            <p class="font-bold">
                                Erwarteter Gebrauchtkaufpreis
                                {{ productData.calculation_residual }}%
                            </p>
                        </td>
                        <td class="pt-10 pb-4 sm:pt-4">
                            <p>{{ getLocalePrice(0) }}</p>
                        </td>
                        <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                            <p class="pt-2 mx-auto font-bold">
                                Erwarteter Gebrauchtkaufpreis {{ productData.calculation_residual }}
                            </p>
                        </td>
                        <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                            <p>
                                {{ getLocalePrice(productData.general_rest) }}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="flex flex-col py-4 text-center text-white bg-black">
                <span>Barkauf minus MPP = Ersparnis von:</span>
                <span class="text-2xl font-bold">
                    {{ getLocalePrice(productData.leasing_net_total) }} ({{
                        productData.saving_percentage
                    }}%)
                </span>
            </div>
            <div class="pt-4 pb-8">
                <span
                    >Berechnung ohne Gewähr. Bitte wenden Sie sich an Ihren Steuerberater, um eine
                    verbindliche Kalkulation zu erhalten.</span
                >
            </div>
        </div>
    </div>
</template>

<script>
import config from '../../config/calculator.config.js'
import { debounce } from 'lodash'
import axios from 'axios'

export default {
    props: {
        url: {
            type: String,
            default: '',
        },
        token: {
            type: String,
            default: '',
        },
        auth_token: {
            type: String,
            default: '',
        },
        employer_grant: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            taxClasses: [1, 2, 3, 4, 5, 6],
            showError: false,
            showFields: false,
            showTable: true,
            isloading: false,
            vat: config.mpp.budget.vat,
            maxValue: config.mpp.budget.max * config.mpp.budget.vat,
            minValue: config.mpp.budget.min * config.mpp.budget.vat,
            healthMinValue: config.healthinsurancecontribution.min,
            healthMaxValue: config.healthinsurancecontribution.max,
            formdata: {
                salary: { ...config.mpp.defaults.salary },
                products: {
                    id: config.mpp.defaults.products.id,
                    price: config.mpp.defaults.products.price,
                },
            },
            calculatorValues: config.mpp.budget,
            apiData: {},
            header: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                Accept: 'application/x.calculator.v1+json',
                'cache-control': 'no-cache',
            },
            sendData: debounce(this.sendRequestToCalculator, 150),
        }
    },
    beforeMount() {
        if (
            JSON.parse(this.employer_grant).fixed &&
            JSON.parse(this.employer_grant).fixed.value > 0
        ) {
            this.formdata.salary.grant_type = 'EUR'
        } else {
            this.formdata.salary.grant_type = 'percent'
        }
    },
    computed: {
        grantConfigObjects() {
            return JSON.parse(this.employer_grant)
        },
        toggleGrantType() {
            return (this.grantConfigObjects.fixed && this.grantConfigObjects.fixed.value > 0) ||
                this.grantConfigObjects.leasing.value > 0
                ? 'hidden'
                : ''
        },
        grantType() {
            return (this.grantConfigObjects.fixed && this.grantConfigObjects.fixed.value > 0) ||
                this.formdata.salary.grant_type == 'EUR'
                ? 'EUR'
                : 'percent'
        },
        grantPercentType() {
            return (this.grantConfigObjects.fixed && this.grantConfigObjects.fixed.value > 0) ||
                this.formdata.salary.grant_type == 'EUR'
                ? 'hidden'
                : ''
        },
        grantFixedType() {
            return this.grantConfigObjects.leasing.value > 0 ||
                (this.formdata.salary.grant_type == 'percent' &&
                    this.grantConfigObjects.fixed &&
                    this.grantConfigObjects.fixed.value == 0 &&
                    this.grantPercentType !== 'hidden')
                ? 'hidden'
                : ''
        },
        amounts() {
            return this.productData.leasing.gross
        },
        net_amounts() {
            return this.productData.leasing.net
        },
        errorMessagePercent() {
            return this.formdata.salary.grant_percentage === '' &&
                this.formdata.salary.grant_type == 'percent'
                ? 'Bitte geben Sie einen gültigen Wert an'
                : ''
        },
        errorMessageFixed() {
            return this.formdata.salary.grant_fixvalue === '' &&
                this.formdata.salary.grant_type == 'EUR'
                ? 'Bitte geben Sie einen gültigen Wert an'
                : ''
        },
        errorMessageGross() {
            return this.formdata.salary.grossmonthlysalary === ''
                ? 'Bitte geben Sie einen gültigen Wert an'
                : ''
        },
        products() {
            return {
                authorization: this.auth_token,
                salary: {
                    ...this.formdata.salary,
                },
                products: {
                    1: {
                        ...this.formdata.products,
                    },
                },
            }
        },
        setGrantPercentage() {
            if (this.formdata.salary.grant_percentage >= 99) {
                this.formdata.salary.grant_percentage = 99
                return
            }
        },
        productData() {
            if (!this.apiData.products_calculated) {
                return {}
            }

            return this.apiData && this.apiData.products_calculated
                ? Object.keys(this.apiData.products_calculated[this.formdata.products.id])
                      .map((productPrice) => {
                          return this.apiData.products_calculated[this.formdata.products.id][
                              productPrice
                          ]
                      })
                      .pop()
                : {}
        },
        productDetails() {
            return this.productData.details
        },
        hasProductData() {
            return this.productData ? Object.keys(this.productData).length > 0 : false
        },
        isValidated() {
            return (
                parseFloat(this.formdata.products.price) !== 0 &&
                parseFloat(this.formdata.products.price) > 0 &&
                parseFloat(this.formdata.salary.grossmonthlysalary) !== 0 &&
                parseFloat(this.formdata.salary.grossmonthlysalary) > 0
            )
        },
        isBudgetAndValid() {
            return (
                this.isBudget(this.formdata.products.price) &&
                this.isBudget(this.formdata.salary.grossmonthlysalary, 'brutto') &&
                this.isValidated
            )
        },
    },
    created() {
        this.formdata.salary.healthinsurancecontribution =
            this.formdata.salary.healthinsurancecontribution == 0
                ? '0.0'
                : this.formdata.salary.healthinsurancecontribution
    },
    methods: {
        showFurtherFields() {
            this.showFields = !this.showFields
        },
        isBudget(price, field = '') {
            return (
                field !== '' ||
                (parseFloat(price) >= parseFloat(this.calculatorValues.min) &&
                    parseFloat(price) <= parseFloat(this.calculatorValues.max))
            )
        },
        budgetMessage(price, field = '') {
            if (price < this.calculatorValues.min) {
                return `Der angegebene Preis ist zu niedrig. Dieser muss größer sein als ${this.getLocalePrice(
                    this.calculatorValues.min,
                )}`
            } else if (price > this.calculatorValues.max && field === '') {
                return `Der angegebene Preis ist zu hoch. Dieser muss niedriger sein als ${this.getLocalePrice(
                    this.calculatorValues.max,
                )}`
            }
            return ''
        },
        showCalculation() {
            this.showTable = !this.showTable
        },
        async sendRequestToCalculator() {
            this.isloading = true

            if( this.formdata.salary.healthinsurancecontribution > config.healthinsurancecontribution.max){
                this.formdata.salary.healthinsurancecontribution = config.healthinsurancecontribution.max
            }
            if( this.formdata.salary.healthinsurancecontribution < config.healthinsurancecontribution.min){
                this.formdata.salary.healthinsurancecontribution = config.healthinsurancecontribution.min
            }

            if (
                !isNaN(this.formdata.products.price) &&
                this.formdata.products.price >= this.minValue &&
                this.formdata.products.price <= this.maxValue &&
                this.formdata.salary.grossmonthlysalary !== ''
            ) {
                await axios
                    .post(
                        this.url + '/api/calculate?withoutHash=1',
                        { ...this.products },
                        { headers: this.header },
                    )
                    .then((data) => {
                        this.showError = false
                        this.apiData = data.data
                    })
                    .catch(() => {
                        this.showError = true
                    })
            }
        },
        getLocalePrice(price) {
            return price === undefined
                ? ''
                : new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                  }).format(parseFloat(price.toString().split(',').join('.')))
        },
        customerHasChildren() {
            if (this.formdata.salary.child === 0) {
                this.formdata.salary.childtax = 0
            }
        },
        transformFormData(rep_prefix, prefix) {
            this.formdata.salary.grossmonthlysalary = this.formdata.salary.grossmonthlysalary
                .split(rep_prefix)
                .join(prefix)
            this.formdata.products.price = this.formdata.products.price
                .split(rep_prefix)
                .join(prefix)
        },
    },
    async mounted() {
        await this.sendRequestToCalculator()
    },
}
</script>

<style scoped>
select {
    text-align-last: center;
}
</style>
